import { useContext } from "react";

import Card from "../../components/Card";
import Content from "../../components/Content";
import { getImageURL } from "../../components/getImageURL";
import { Layout } from "../../components/Layout";
import { LangContext } from "../../context";
import { OperatingSystemContent } from "./translate";

export const OperatingSystemPage = () => {

    const { currentLang } = useContext(LangContext);
    const text = OperatingSystemContent;
    const title = text[currentLang].title;
    const content = text[currentLang].content;
  
    return (
      <Layout>
        <Card
          title={title}
          img={getImageURL("operatingsystem.png")}
          srcSet={` ${getImageURL('operatingsystem-mobile.avif')} 360w,  ${getImageURL('operatingsystem.png')}`}
        />
        {
                  content && (
                          <Content image={getImageURL('operativesystem.png')}  text={content.text}/>
                  )
        }
      </Layout>
    )
}
