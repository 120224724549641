import { useState } from "react";

export const useCarousel = (content) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleBackContent = async () => {
    const backIndex = (currentIndex - 1 + content.length) % content.length;
    setCurrentIndex(backIndex);
  };

  const handleNextContent = async () => {
    const nextIndex = (currentIndex + 1) % content.length;
    setCurrentIndex(nextIndex);
  };


  return {
    currentIndex,
    handleBackContent,
    handleNextContent
  }
}