import { Layout } from "../components/Layout"
import { WeAre } from "../components/WeAre"
import { HomeBanner } from "../components/HomeBanner.jsx"
import { Guarantees } from "../components/Guarantees.jsx"
import { WhoAreWe } from "../components/WhoAreWe.jsx"
import { Stadistics } from "../components/Stadistics.jsx"
import { Offices } from "../components/Offices.jsx"
import { Buzon } from "../components/Buzon.jsx"
import { Certifications } from "../components/Certifications.jsx"
import { StrategicAllies } from "../components/StrategicAllies.jsx"
import { OurHistory } from "../components/OurHistory.jsx"


export const HomePage = () => {
  return (
    <Layout>
      <HomeBanner />
      <WeAre />
      <Guarantees />
      <WhoAreWe />
      <Stadistics />
      <Offices />
      <OurHistory />
      <Certifications />
      <StrategicAllies />
      <Buzon />
    </Layout>
  )
}

