import { useContext } from "react";

import Card from "../../components/Card";
import Content from "../../components/Content";
import { getImageURL } from "../../components/getImageURL";
import { Layout } from "../../components/Layout";
import { LangContext } from "../../context";
import { EmissionSystemContent } from "./translate";
import { ilsUrls } from "../../types/urls";

export const EmissionSystemPage = () => {
    const { currentLang } = useContext(LangContext);
    const text = EmissionSystemContent;
    const title = text[currentLang].title;
    const content = text[currentLang].content;
    const handleClick = () => {
        window.open( ilsUrls[currentLang] ,'_blank')
      }
  
    return (
      <Layout>
        <Card
          title={title}
          img={getImageURL("emissionsystem.png")}
          srcSet={`${getImageURL('emissionsystem-mobile.avif')} 360w,  ${getImageURL('emissionsystem.png')}`}
        />
        {
                  content && (
                          <Content image={getImageURL('equipment.png')} handleClick={handleClick} text={content.text} left/>
                  )
        }
      </Layout>
    )
}
