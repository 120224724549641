export default function Global() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="30" height="30" rx="15" fill="#004899" />
      <path
        d="M14.8292 4.01846C23.6644 3.52217 29.5066 13.1353 24.8672 20.7473C20.7034 27.5794 10.8948 27.7745 6.39255 21.1696C1.61441 14.1591 6.41771 4.49111 14.8292 4.01846ZM13.4984 10.6333H17.4991C17.5167 10.6333 17.6005 10.5479 17.5761 10.5022C17.1157 8.97749 16.41 7.53438 15.4984 6.2323C14.7156 7.44366 13.8353 8.92641 13.487 10.3367C13.458 10.4526 13.3734 10.5563 13.4977 10.6333H13.4984ZM7.92531 10.6333H11.0404C11.2111 10.3314 11.2583 9.93423 11.3712 9.59194C11.6989 8.60165 12.1333 7.64187 12.612 6.71638C12.6524 6.58983 11.766 7.02361 11.6966 7.05867C10.3231 7.75469 8.96569 8.94013 8.13034 10.2376C8.09375 10.2948 7.89254 10.6043 7.92531 10.6325V10.6333ZM23.0722 10.6333C22.9625 10.3825 22.8047 10.1088 22.6507 9.88239C21.8253 8.66417 20.3771 7.52523 19.0342 6.92526C18.9808 6.90163 18.3528 6.61347 18.3855 6.71638C18.8497 7.63729 19.3009 8.61233 19.6264 9.59194C19.74 9.93347 19.7864 10.3314 19.9572 10.6333H23.0722ZM10.7835 12.806H7.01145C6.61587 14.2415 6.61587 15.7715 7.01145 17.2077H10.7835C10.7012 15.7486 10.5404 14.2567 10.7835 12.806ZM18.1569 12.8052L12.958 12.8083C12.6836 14.2925 12.692 15.7181 12.958 17.2039H18.0395L18.0753 16.9729C18.2727 15.7021 18.3101 14.4122 18.1881 13.1323L18.1569 12.8052ZM24.1004 12.806H20.3284C20.3947 14.2704 20.5631 15.7463 20.3284 17.2077H24.1004C24.4884 15.7539 24.4884 14.2582 24.1004 12.806ZM11.0404 19.3804H7.92531C8.32241 20.2053 8.95502 20.9767 9.63947 21.582C10.1753 22.0562 11.8643 23.2127 12.5266 23.3255C12.5678 23.3324 12.6249 23.337 12.612 23.2966C12.1387 22.368 11.6997 21.412 11.3712 20.421C11.3155 20.254 11.128 19.4078 11.0404 19.3796V19.3804ZM17.5845 19.3804H13.4123C13.8079 20.9668 14.58 22.4404 15.4984 23.7814C16.4161 22.4389 17.1531 20.9546 17.5845 19.3804ZM23.0722 19.3804H19.9572C19.7781 19.712 19.7232 20.1427 19.602 20.5117C19.2865 21.4738 18.8695 22.4084 18.3855 23.2966C18.3726 23.337 18.4298 23.3324 18.4709 23.3255C18.9458 23.2447 20.262 22.426 20.6889 22.1142C21.6546 21.4082 22.5471 20.4637 23.0715 19.3804H23.0722Z"
        fill="white"
      />
    </svg>
  );
}
