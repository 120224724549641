(function () {
    // Verificar si NO estamos en localhost
    function isNotLocalhost() {
        const hostname = window.location.hostname;
        return !['localhost', '127.0.0.1', ''].includes(hostname);
    }

    function isMobile() {
        return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
    }

    // Solo ejecutar si NO estamos en localhost y no es dispositivo móvil
    if (isNotLocalhost() && !isMobile()) {
        document.addEventListener("keydown", function (event) {
            if (
                event.keyCode === 123 ||
                (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
                (event.ctrlKey && event.shiftKey && event.keyCode === 74) ||
                (event.ctrlKey && event.keyCode === 85) ||
                (event.ctrlKey && event.shiftKey && event.keyCode === 67)
            ) {
                event.preventDefault();
                alert("Inspeccionar está deshabilitado 🚫");
            }
        });

        // Bloquear Click Derecho
        document.oncontextmenu = function () {
            alert("¡Acción no permitida! 🚫");
            return false;
        };

        // Detectar consola abierta y bloquear solo en escritorio
        let detectConsole = function () {
            if (window.outerWidth - window.innerWidth > 160 || window.outerHeight - window.innerHeight > 160) {
                document.body.innerHTML = `
                    <style>
                        body { background: black; color: red; text-align: center; padding-top: 20%; }
                        h1 { font-family: Arial, sans-serif; }
                    </style>
                    <h1>ACCESO DENEGADO 🚨</h1>
                `;
            }
        };

        window.addEventListener("resize", detectConsole);
        detectConsole();

        // Mostrar mensaje en consola
        setInterval(() => {
            console.log("%c¡Acceso Restringido! 🚨", "color: red; font-size: 20px; font-weight: bold;");
        }, 1000);

        // Bloquear consola con debugger
        setInterval(() => {
            (function () {
                debugger;
            })();
        }, 100);
    }
})();