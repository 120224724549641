// ScriptLoader.js - Crea este componente en tu proyecto React
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { languagesTypes } from '../types/types';


function loadScript(url, defer = true) {



    return new Promise((resolve, reject) => {
        // Verificar si el script ya existe para evitar duplicados
        const existingScript = document.querySelector(`script[src="${url}"]`);
        if (existingScript) {
            return resolve();
        }

        const script = document.createElement("script");
        script.src = url;
        script.rel = "preconnect";
        script.defer = defer;
        script.crossorigin = "anonymous";
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
}

function loadCookies() {

    const languageConfig = {
        [languagesTypes.english]: 'en',
        [languagesTypes.spanish]: 'es',
        [languagesTypes.portugues]: 'pt'
    }

    const currentLang = localStorage.getItem('lang') || languagesTypes.english

    console.log(languageConfig[currentLang])

    window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", "UA-30046428-49");

    // Verificar si cookieconsent está disponible
    if (window.cookieconsent) {
        window.cookieconsent.run({
            notice_banner_type: "headline",
            consent_type: "express",
            palette: "dark",
            language: languageConfig[currentLang],
            website_name: "wtabyhas.com",
        });
    }

    document.cookie = "user_session=abcd1234; SameSite=Lax; Secure";

    // Verificar si jQuery está disponible
    if (window.jQuery) {
        window.jQuery("body,html").animate({ scrollTop: 0 }, 500);
    }
}

function ScriptLoader() {
    const location = useLocation();

    useEffect(() => {
        const createScripts = () => {
            // Cargar scripts de forma asíncrona
            Promise.all([
                loadScript("https://www.google.com/recaptcha/api.js"),
                loadScript("https://www.googletagmanager.com/gtag/js?id=UA-30046428-49"),
                loadScript("https://www.freeprivacypolicy.com/public/cookie-consent/3.1.0/cookie-consent.js"),
                loadScript("https://code.jquery.com/jquery-3.6.0.min.js"),
            ])
                .then(() => {
                    // Una vez que los scripts estén cargados, ejecutar loadCookies
                    loadCookies();
                })
                .catch((error) => {
                    console.error("Error al cargar los scripts:", error);
                });
        };

        // Configurar event listener para el botón de cookies
        const setupCookiesButton = () => {
            const cookiesButton = document.getElementById("obj");
            if (cookiesButton) {
                cookiesButton.onclick = (event) => {
                    event.preventDefault();
                    createScripts();
                };
            } else {
                // Si el botón no existe, intentar de nuevo en 100ms
                setTimeout(setupCookiesButton, 100);
            }
        };

        // Ejecutar la configuración después de que la navegación se complete
        setupCookiesButton();

        // Limpieza al desmontar el componente
        return () => {
            const cookiesButton = document.getElementById("obj");
            if (cookiesButton) {
                cookiesButton.onclick = null;
            }
        };
    }, [location]); // Esta dependencia hará que el efecto se ejecute cada vez que la ruta cambie

    return null; // Este componente no renderiza nada visualmente
}

export default ScriptLoader;