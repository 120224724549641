/* eslint-disable react/prop-types */
import { motion } from "framer-motion";

export default function Content({ image, text, left = false, handleClick }) {



    return (
        <div
        className="
        px-[5%]
        "
        >

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="flex flex-col-reverse md:flex-row max-w-7xl gap-0 md:gap-20 p-8 justify-center items-center md:my-[80px] mx-auto"
        >

            <motion.img
                onClick={handleClick}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className={`${!left ? '' : 'md:order-1'} sm:order-0 h-auto w-auto flex items-center justify-end cursor-pointer p-5 md:px-0`}
                alt="Image"
                src={image}
                width={'100%'}
            ></motion.img>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                {text.map((item, index) => (
                    <motion.p className="mt-10 sm:text-[14px] md:text-xl text-gray-900 justify-center items-center text-justify font-medium" key={`paragraph ${index}`}>
                        {item}
                    </motion.p>
                ))}
            </motion.div>
            
        </motion.div>

        </div>
    );
}
