import { useContext } from "react"

import { getImageURL } from "./getImageURL"
import { homePageContent } from "../pages/translate"
import { LangContext } from "../context"

export const Guarantees = () => {

    const { currentLang } = useContext(LangContext)

    return (
        <section className="flex flex-col md:flex-row-reverse items-center px-[10px] md:px-[106px] py-[50px] md:py-[43px] gap-[22px] md:gap-[70px]" id="AboutUs">
            <div className="w-full 2xl:w-[30%] md:w-[30%]">
                <p className="font-semibold text-[20px] 2xl:text-[40px] md:text-[40px] 2xl:leading-[65px] leading-[25px] md:leading-[60px] text-[#021C39ED] p-2 md:p-0 text-center md:text-left">{homePageContent[currentLang].supportSecurity}</p>
            </div>
            <div className="w-full md:w-[60%]">
                <img src={getImageURL('we_are_img.avif')} alt="" width={'100%'} loading="lazy" />
            </div>

            

        </section>
    )
}
