import { useForm } from "react-hook-form"
import Swal from 'sweetalert2'
import { useContext } from "react"

import { homePageContent } from "../pages/translate"
import { LangContext } from "../context"
import { languagesTypes } from "../types/types"

export const Buzon = () => {

  const { currentLang } = useContext(LangContext)

  const fieldMessage = {
    [languagesTypes.spanish]: 'Campo es requerido',
    [languagesTypes.english]: 'Field is required',
    [languagesTypes.portugues]: 'Campo obrigatório',
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    let form_data = new FormData();

    for (let key in data) {
      form_data.append(key, data[key]);
    }

    try {
      const response = await fetch(
        "https://assistanceform.com/php/mail_has.php",
        {
          method: "POST",
          body: form_data,
        }
      );

      const result = await response.json();
      if (result.status === "ok") {
        Swal.fire("Formulario enviado!", "", "success");
        // Reset the form
        reset();
      } else {
        Swal.fire("Error al enviar el formulario", "", "error");
      }
    } catch (error) {
      Swal.fire(`Error al enviar el formulario ${error}`, "", "error");
    }

  }

  return (
    <section id="buzonForm" className="bg-[#004899] mb-[70px] md:mb-[60px] flex flex-col md:flex-row justify-center md:px-[45.73px] py-[20px] md:py-[37px] overflow-hidden">
      <div className="w-full max-w-[1500px] py-[30px] px-[30px]">

        <div className="relative flex flex-col before:block before:h-[120%] before:w-[200%] before:top-[-43%] before:left-[-78%] before:md:w-[660px] before:md:h-[730px] before:2xl:w-[560px] before:2xl:h-[860px] before:bg-transparent before:absolute before:rotate-[73deg] before:md:rotate-[73deg] shadow-custom before:md:left-[-35%] before:md:top-[-83%] before:2xl:left-[1%] before:2xl:top-[-110%]">
          <p className="text-[#FFFFFF] w-[85%] md:w-auto font-bold text-[20px] md:text-[27px] leading-[37.08px] pb-5 md:p-0">{homePageContent[currentLang].buzon.title}</p>
          <ul className="flex flex-col gap-[34px] px-[5px] py-[20px] md:px-[17.24px] md:py-[42px]">
            <li className="flex items-center gap-[29px]">
              <svg width="27" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2039_89)">
                  <path d="M8.5878 11.5965C9.25841 14.416 12.8989 17.9204 15.5973 18.5895C16.8107 18.8603 18.152 18.1912 18.5671 16.9806C19.11 15.2284 21.3932 14.6868 22.7504 16.0408L26.5186 19.8001C27.4607 20.7399 27.4607 22.3487 26.5186 23.4319L23.9639 25.9806C21.2655 28.6726 14.1123 25.7098 7.77349 19.3859C1.4506 13.078 -1.3915 5.9576 1.17917 3.26556L3.73387 0.700961C4.67592 -0.238862 6.30454 -0.238862 7.37432 0.700961L11.1425 4.46025C12.4837 5.79831 11.9568 8.09211 10.2005 8.6337C8.98697 9.03193 8.17266 10.3859 8.5878 11.5965Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_2039_89">
                    <rect width="27" height="27" fill="white" transform="translate(0.237061)" />
                  </clipPath>
                </defs>
              </svg>
              <a className="text-[#FFFFFF] font-normal underline text-[15px] md:text-[19.33px] leading-[29.04px]" href="">+1 305 328 3897</a>
            </li>
            <li className="flex items-center gap-[29px]">
              <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2039_92)">
                  <path d="M29.2203 2.11187C29.2203 2.11187 29.237 2.04429 29.237 2.0105L20.3906 10.6774L29.237 19.0572C29.237 19.0572 29.237 18.9389 29.237 18.8713V2.11187H29.2203Z" fill="white" />
                  <path d="M19.0438 12.012L15.4354 15.5429C15.2525 15.7288 15.0197 15.8132 14.7869 15.8132C14.5541 15.8132 14.3213 15.7288 14.1384 15.5598L10.53 12.1471L1.61719 20.8478C1.83336 20.9323 2.06616 20.983 2.31558 20.983H27.225C27.5908 20.983 27.9234 20.8647 28.2227 20.7127L19.0438 12.012Z" fill="white" />
                  <path d="M14.7537 13.5326L28.256 0.304103C27.9567 0.118262 27.5908 0 27.2084 0H2.3156C1.81675 0 1.36778 0.202735 1.00195 0.489944L14.7537 13.5157V13.5326Z" fill="white" />
                  <path d="M0.237061 2.43286V18.8882C0.237061 19.0741 0.286946 19.2599 0.320203 19.4288L9.10002 10.8295L0.237061 2.43286Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_2039_92">
                    <rect width="29" height="21" fill="white" transform="translate(0.237061)" />
                  </clipPath>
                </defs>
              </svg>

              <a className="text-[#FFFFFF] font-normal underline text-[15px] md:text-[19.33px] leading-[29.04px]" href="">info@hascompanies.com</a>
            </li>
            <li className="flex items-center gap-[29px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="31" viewBox="0 0 24 31" fill="none">
                <g clipPath="url(#clip0_2039_86)">
                  <path d="M12.4274 0C6.08063 0 0.920898 4.92255 0.920898 10.9776C0.920898 17.0326 11.4461 30.1695 11.8893 30.7433C12.0159 30.9094 12.2217 31 12.4274 31C12.6332 31 12.8389 30.9094 12.9656 30.7433C13.4087 30.1846 23.9339 16.8363 23.9339 10.9776C23.9339 5.11885 18.7742 0 12.4274 0ZM12.4274 16.791C9.07201 16.791 6.33387 14.1788 6.33387 10.9776C6.33387 7.77642 9.07201 5.16415 12.4274 5.16415C15.7828 5.16415 18.521 7.77642 18.521 10.9776C18.521 14.1788 15.7828 16.791 12.4274 16.791Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_2039_86">
                    <rect width="23.013" height="31" fill="white" transform="translate(0.920898)" />
                  </clipPath>
                </defs>
              </svg>

              <p className="text-[#FFFFFF] font-normal text-[15px] md:text-[19.33px] leading-[29.04px]">1571 Sawgrass Corporate Pkwy <br /> Suite 100 Sunrise, <br />
                Fl, 33323-2862</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full mt-20 mf:mt-0 px-[20px] md:px-[100px]">
        <form className="flex flex-col gap-[21.96px]" onSubmit={handleSubmit(onSubmit)} noValidate='novalidate' id="main_contact_form">
          <div className="flex flex-col gap-1">
            <input
              name="name"
              type="text"
              placeholder={homePageContent[currentLang].buzon.form.name}
              className="w-full border border-solid border-[#FFFFFF] px-[23.01px] py-[11px] text-[#FFFFFF] font-light text-[19.36px] leading-[23.6px] outline-0"
              {...register("name", { required: fieldMessage[currentLang] })}
            />
            {errors.name && <p className="text-[white]">{errors.name.message}</p>}
          </div>

          <div className="flex flex-col gap-1">
            <input type="email" placeholder={homePageContent[currentLang].buzon.form.email} className="w-full border border-solid border-[#FFFFFF] px-[23.01px] py-[11px] text-[#FFFFFF] font-light text-[19.36px] leading-[23.6px] outline-0" name="email"
              {...register("email", { required: fieldMessage[currentLang] })}
            />
            {errors.email && <p className="text-[white]">{errors.email.message}</p>}
          </div>

          <div className="flex flex-col gap-1">
            <input type="text" placeholder={homePageContent[currentLang].buzon.form.country} className="w-full border border-solid border-[#FFFFFF] px-[23.01px] py-[11px] text-[#FFFFFF] font-light text-[19.36px] leading-[23.6px] outline-0" name="country"
              {...register("country", { required: fieldMessage[currentLang] })}
            />
            {errors.country && <p className="text-[white]">{errors.country.message}</p>}
          </div>

          <div className="flex flex-col gap-1">
            <input type="number" placeholder={homePageContent[currentLang].buzon.form.phone} className="w-full border border-solid border-[#FFFFFF] px-[23.01px] py-[11px] text-[#FFFFFF] font-light text-[19.36px] leading-[23.6px] outline-0" name="phone"
              {...register("phone", { required: fieldMessage[currentLang] })}
            />
            {errors.phone && <p className="text-[white]">{errors.phone.message}</p>}
          </div>

          <div className="flex flex-col gap-1">
            <textarea name="message" id="" placeholder={homePageContent[currentLang].buzon.form.message} className=" outline-0 w-full border border-solid border-[#FFFFFF] px-[23.01px] py-[11px] text-[#FFFFFF] font-light text-[19.36px] leading-[23.6px]"
              {...register("message", { required: fieldMessage[currentLang] })}
            ></textarea>
            {errors.message && <p className="text-[white]">{errors.message.message}</p>}
          </div>



          <div className="flex justify-start gap-[15px] items-center px-[3.83px]">
            <div className="flex items-center gap-[15px]">
              <input type="checkbox" name="check" id="check" className="w-[19px] h-[19px] border border-[#FFFFFF] rounded"
                {...register("check", { required: fieldMessage[currentLang] })}
              />
              <label htmlFor='check'>
                <p className="text-[#FFFFFF] font-light text-[19.36px] leading-[23.6px]">{homePageContent[currentLang].buzon.form.terms}</p>
                {errors.check && <p className="text-[white]">{errors.check.message}</p>}
              </label>
            </div>
          </div>
          <div className="flex justify-center">
            <button type="submit" className="text-[#004899] bg-[#FFFFFF] pt-[12px] pb-[8px] px-[85px] font-bold text-[22.12px] leading-[26.96px] rounded-[29px] cursor-pointer">
              {homePageContent[currentLang].buzon.form.button}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Buzon;

