import { languagesTypes } from "../../types/types";

export const ClaimsAdministratorContent = {
  [languagesTypes.english]: {
    title: "CLAIMS ADMINISTRATOR",
    content: {
      text: [
        `One of our companies, WTA by HAS, acting as a Third Party Administrator (TPA) for services and risks, has been certified as Delegated Claims Authority (DCA) by Lloyd's, the world's largest commercial insurance and reinsurance marketplace.`,
        `This reinforces our ability to establish strong and long lasting partnerships and confirms our service promise by ensuring integrity and excellence at every stage of the process.`,
        "Our rigorous cost-containment process enables us to lead and guarantee top-quality services, improving the finances of our partners through effective negotiations and the proper and efficient logistics of the services provided.",
      ],
    },
  },
  [languagesTypes.spanish]: {
    title: "ADMINISTRADORES DE RECLAMOS",
    content: {
      text: [
        `Una de nuestras empresas, WTA by HAS, que actúa como Administrador de Terceros (TPA) para servicios y riesgos, ha sido certificada como Autoridad Delegada de Reclamaciones (DCA) por Lloyd's, el mayor mercado comercial de seguros y reaseguros del mundo.`,
        `Esto refuerza nuestra capacidad para establecer asociaciones sólidas y duraderas y confirma nuestra promesa de servicio al garantizar la integridad y la excelencia en cada etapa del proceso.`,
        "Nuestro riguroso proceso de contención de costes nos permite liderar y garantizar servicios de máxima calidad, mejorando las finanzas de nuestros socios mediante negociaciones eficaces y una logística adecuada y eficiente de los servicios prestados.",
      ],
    },
  },
  [languagesTypes.portugues]: {
    title: "ADMINISTRADORES DE REIVINDICAÇÕES",
    content: {
      text: [
        `Uma de nossas empresas, WTA by HAS, que atua como Administrador de Terceiros (TPA) para serviços e riscos, foi certificada como Autoridade de Reivindicações Delegada (DCA) por Lloyd’s, o maior mercado comercial de seguros e resseguro do mundo.`,
        `Isto reforça a nossa capacidade de estabelecer parcerias fortes e duradouras e confirma a nossa promessa de serviço, garantindo integridade e excelência em todas as fases do processo.`,
        "Nosso rigoroso processo de contenção de custos permite-nos liderar e garantir serviços de alta qualidade, melhorando as finanças de nossos parceiros por meio de negociações eficazes e uma logística adequada e eficiente dos serviços prestados.",
      ],
    },
  },
};
