import { languagesTypes } from "../../types/types";

export const EmissionSystemContent = {
  [languagesTypes.english]: {
    title: "DIGITAL ECOSYSTEM",
    content: {
      text: [
        "With over 40 technological developments in our portfolio and a a team of experts in Software Engineering and Software Solutions Architecture, we are capable of developing tech solutions ranging from the design and implementation of digital ecosystems with personalized environments to platforms for the issuance and sale of all types of assistance services. ",
        "Our Digital Ecosystems allow our clients to offer self-managed services, providing greater control and autonomy to the end user. This enhances the customer experience by generating flexibility and comfort.",
        "We adapt to the needs of the most demanding clients and are committed to exceeding their expectations.",
      ],
    },
  },
  [languagesTypes.spanish]: {
    title: "ECOSISTEMA DIGITAL",
    content: {
      text: [
        "Con más de 40 desarrollos tecnológicos en nuestro portafolio y un equipo de expertos en Ingeniería de Software y Arquitectura de Soluciones Software, estamos en la capacidad de desarrollar soluciones tecnológicas que van desde el diseño e implementación de ecosistemas digitales con entornos personalizados, hasta plataformas para la emisión y venta de todo tipo de servicios de asistencia.",
        "Nuestros Ecosistemas Digitales permiten a nuestros clientes ofrecer servicios autogestionados, brindando un mayor control y autonomía al usuario final. Esto mejora la experiencia del cliente, generando flexibilidad y comodidad.",
        "Nos adaptamos a las necesidades de los clientes más exigentes y estamos comprometidos a superar sus expectativas.",
      ],
    },
  },
  [languagesTypes.portugues]: {
    title: "ECOSSISTEMA DIGITAL",
    content: {
      text: [
        "Com mais de 40 desenvolvimentos tecnológicos em nosso portfólio e uma equipe de especialistas em Engenharia de Software e Arquitetura de Soluções de Software, temos capacidade para desenvolver soluções tecnológicas que vão desde a concepção e implementação de ecossistemas digitais com ambientes personalizados, até plataformas de emissão e venda de todo o tipo de serviços assistenciais.",
        "Nossos Ecossistemas Digitais permitem que nossos clientes ofereçam serviços autogerenciados, proporcionando maior controle e autonomia ao usuário final. Isso melhora a experiência do cliente, gerando flexibilidade e comodidade.",
        "Nós nos adaptamos às necessidades dos clientes mais exigentes e temos o compromisso de corresponder às suas expectativas.",
      ],
    },
  },
};
