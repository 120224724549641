import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { LangProvider } from './context'
import HasCompaniesApp from './HasCompaniesApp'


createRoot(document.getElementById('root')).render(
  <StrictMode>
    <LangProvider>
      <HasCompaniesApp />
    </LangProvider>
  </StrictMode>,
)
