import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { getImageURL } from "./getImageURL";
import { homePageContent } from "../pages/translate";
import { LangContext } from "../context";

export const StrategicAllies = () => {
    const { currentLang } = useContext(LangContext);

    const strategicImages = [
        { imageUrl: getImageURL('BMI seguros.avif') },
        { imageUrl: getImageURL('binah-ai-logo-black.avif') },
        { imageUrl: getImageURL('lloyds2.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 49.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 26.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 33.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 43.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 8.avif') },
        { imageUrl: getImageURL('logo omint.avif') },
        { imageUrl: getImageURL('logo-uniao-seguradora.ea0772d3.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 54.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 25.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 30.avif') },
        { imageUrl: getImageURL('Falabella.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 32.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 37.avif') },
        { imageUrl: getImageURL('lapositivaseguros 1.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 42.avif') },
        { imageUrl: getImageURL('General.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 51.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 52.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 59.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 38.avif') },   
        { imageUrl: getImageURL('Canopius-logo-new.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 31.avif') },
        { imageUrl: getImageURL('Sompo.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 36.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 27.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 28.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 29.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 34.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 35.avif') },
        { imageUrl: getImageURL('IZA logo.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 45.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 46.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 48.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 56.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 57.avif') },
        { imageUrl: getImageURL('Mesa de trabajo 58.avif') },
    ];

    return (
        <section className="flex flex-col py-[50px] md:py-[50px] px-[20px] md:px-[120px] font-extrabold text-[#004899] text-center gap-[40px]">
            <p className="font-extrabold text-[30px] 2xl:text-[45px] md:text-[40px] leading-[40px]">
                {homePageContent[currentLang].strategicAllies}
            </p>

            {isMobile ? (
                <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 items-center justify-center">
                    {strategicImages.map((stra) => (
                        <div key={stra.imageUrl} className="flex justify-center items-center">
                            <img src={stra.imageUrl} alt="" className="w-full max-w-[120px]" loading="lazy" />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-center">
                    <img src={getImageURL('aliados.avif')} alt="Strategic Allies" className="max-w-full" loading="lazy" />
                </div>
            )}
        </section>
    );
};
