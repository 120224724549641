/* eslint-disable react/prop-types */

import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { getImageURL } from "./getImageURL";
import { homePageContent } from "../pages/translate";
import { LangContext } from "../context";

export const Offices = () => {
    const { currentLang } = useContext(LangContext);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const countries = [
        { name: "United States", coordinates: { top: "27%", left: "23%" }, services: "Contact center" },
        { name: "Saint Kitts And Nevis", coordinates: { top: "37%", left: "30%" } },
        { name: "Mexico", coordinates: { top: "34%", left: "20%" }, services: "Contact center" },
        { name: "Colombia", coordinates: { top: "43%", left: "29%" }, services: "Contact center" },
        { name: "Brazil", coordinates: { top: "53%", left: "35%" }, services: "Contact center" },
        { name: "Peru", coordinates: { top: "53%", left: "27%" } },
        { name: "Chile", coordinates: { top: "60%", left: "28%" } },
        { name: "Argentina", coordinates: { top: "67%", left: "30%" } },
        { name: "Spain", coordinates: { top: "19%", left: "46%" } },
        { name: "Italy", coordinates: { top: "16%", left: "50%" } },
        { name: "India", coordinates: { top: "35%", left: "66%" } },
    ];

    useEffect(() => {
        if (!hasAnimated) setHasAnimated(true);
    }, [hasAnimated]);

    return (
        <section className="flex flex-col gap-6 md:gap-10 py-4 2xl:py-[150px] md:py-12">
            <p className="font-extrabold leading-[25px] md:leading-[44px] text-center text-[#004899] text-16px] md:text-[40px] px-[20px] md:px-[153px]">
                {homePageContent[currentLang].offices}
            </p>
            {isMobile ? (
                <ImageMobile />
            ) : (
                <ImageDesktop hasAnimated={hasAnimated} countries={countries} />
            )}
        </section>
    );
};


const ImageMobile = () => {
    return (

        <img
            src={getImageURL("mapGlobal.avif")}
            alt="global map"
            className="block w-full h-52 object-cover"
            loading="lazy"
        />
    )
}

const ImageDesktop = ({ hasAnimated, countries }) => {
    return (
        <div className="relative w-full max-w-[1500px] min-h-[300px] md:min-h-[500px] mx-auto rounded-lg overflow-hidden shadow-xl bg-cover bg-center bg-no-repeat md:bg-none"
            style={{ backgroundImage: `url(${getImageURL("fondoGradiente.avif")})` }}>
            <motion.img
                src={getImageURL("mapBackground.png")}
                alt="Map Background img"
                className="absolute inset-0 w-full h-full object-cover"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={hasAnimated ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.8 }}
            />

            {countries.map(({ name, coordinates, services }, index) => (
                <motion.div
                    key={index}
                    className="absolute flex items-center gap-2"
                    style={{
                        top: coordinates.top,
                        left: coordinates.left,
                        transform: "translate(-50%, -50%)",
                    }}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={hasAnimated ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
                    transition={{ duration: 0.4, delay: index * 0.1 }}
                >
                    <img
                        src={getImageURL("direction.png")}
                        alt="Direction Icon"
                        className="w-5 h-5 md:w-7 md:h-7"
                    />
                    <div className="text-white text-xs md:text-sm">
                        <p className="font-semibold">{name}</p>
                        {services && <p className="opacity-80">{services}</p>}
                    </div>
                </motion.div>
            ))}
        </div>
    )

}