import { useContext } from "react"
import { homePageContent } from "../pages/translate"
import { getImageURL } from "./getImageURL"
import { LangContext } from "../context"


export const Certifications = () => {

    const { currentLang } = useContext(LangContext)

    const certificationsImages = [
        {
            size: "md:h-[75px] md:w-[244px]",
            imageUrl: getImageURL("WTA_and_Lloyds.avif")
        },
        {
            size: "md:h-[95px] md:w-[176px]",
            imageUrl: getImageURL("HIPAA.avif")
        },
        {
            size: "md:h-[115px] md:w-[126px]",
            imageUrl: getImageURL("AICPA.avif")
        },
        {
            size: 'md:w-[160px] md:h-[89px]',
            imageUrl: getImageURL("liquid_web.avif")
        },
        {
            size: "h-auto w-[50%] md:h-[99px] md:w-[68px]",
            imageUrl: getImageURL("Isolation_Mode.avif")
        },
        {
            size: 'h-auto w-[50%] md:h-[99px] md:w-[68px]',
            imageUrl: getImageURL("iso_27001.avif")
        },
    ]

    return (
        <section className='flex flex-col px-[30px] md:px-[105px] py-[50px] md:py-[50px] gap-[40px] md:gap-[100px]'>
            <p className='font-extrabold text-[20px] md:text-[40px] 2xl:text-[45px] leading-[25px] md:leading-[40px] text-center text-[#004899]'>{homePageContent[currentLang].certifications}</p>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-y-[35px] gap-x-[30px]  md:gap-y-[57px]">
                {certificationsImages.map(cer => (
                    <div key={cer.imageUrl} className="flex justify-center items-center">
                        <img
                            src={cer.imageUrl}
                            alt="Logo company"
                            width={"100%"}
                            className={cer.size}
                            loading="lazy"
                        />

                    </div>
                ))}
            </div>
        </section>
    )
}
