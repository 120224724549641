import { getImageURL } from "./getImageURL"
import { useContext } from "react"

import { homePageContent } from "../pages/translate"
import { LangContext } from "../context"

export const WhoAreWe = () => {

    const { currentLang } = useContext(LangContext)

    const images = [
        getImageURL('waw1.avif'),
        getImageURL('waw2.avif'),
        getImageURL('waw3.avif'),
        getImageURL('waw4.avif'),
    ]

    return (
        <section id="whoWeAre" className='p-[20px] md:px-[130px] md:py-[25px] flex md:flex-row flex-col items-center gap-[40px]'>
            <div className='flex flex-col gap-[22] md:gap-[32px] w-full'>
                <p className='text-[#004899] font-extrabold text-[28px] 2xl:text-[60px] md:text-[48px] leading-[58.51px] text-center'>{homePageContent[currentLang].whoWeAre.title}</p>
                <p className='font-normal text-[14px] 2xl:text-[35px] md:text-[28px] 2xl:leading-[35px] md:leading-[30px] text-[#000000] text-center md:text-left'>{homePageContent[currentLang].whoWeAre.text}</p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-[17.56px] w-full">
                {images.map((img, index) => (
                    <div key={`Image ${index}`}>
                        <img src={img} alt={`Image ${index}`} width={'100%'} loading="lazy" />
                    </div>
                ))}
            </div>
        </section>
    )
}
