import { languagesTypes } from "../../types/types";


export const technologicalSolutionsContent = {
    [languagesTypes.english]: {
        title: 'TECHNOLOGICAL SOLUTIONS FOR HEALTH',
        content: {
            text: [
                `At HAS, we have Elite Global Doctors, a Health Tech division specialized in developing 
                advanced technology for preventive and proactive medicine. This unit enables the 
                monitoring of large populations and real-time risk analysis. Among the variables
                analyzed are cardiac risk, hypertension, and type 2 diabetes, using data collected
                through medical devices, chatbots, and virtual screening tools. Additionally,
                Health Data Management, an intelligent dashboard, provides key health insights
                to support efficient decision-making.`
            ]
        },
    },
    [languagesTypes.spanish]: {
        title: 'SOLUCIONES TECNOLÓGICAS PARA LA SALUD',
        content: {
            text: [
                `En HAS contamos con Elite Global Doctors una Health Tech, división especializada en desarrollar tecnología avanzada para la medicina
                preventiva y proactiva. Esta unidad permite monitorear grandes poblaciones y realizar análisis de riesgo en tiempo real. Entre
                las variables analizadas se incluyen riesgo cardíaco, hipertensión y diabetes tipo 2, utilizando datos recopilados mediante
                equipos médicos, chatbots y herramientas de screening virtual. Además, Health Data Management, un dashboard inteligente, 
                proporciona información clave en salud para respaldar una toma de decisiones eficiente.`
            ]
        }
    },
    [languagesTypes.portugues]:{
        title: 'SOLUÇÕES TECNOLÓGICAS PARA A SAÚDE',
        content: {
            text: [
                `Na HAS, contamos com a Elite Global Doctors una Health Tech, divisão especializada no desenvolvimento de tecnologia avançada para medicina preventiva e proativa. Esta unidade permite monitorar grandes populações e realizar análises de risco em tempo real. Entre as variáveis analisadas estão risco cardíaco,
                hipertensão e diabetes tipo 2, utilizando dados coletados por equipamentos médicos, chatbots e ferramentas de screening virtual. Além do mais, Health Data Management, uma dashboard inteligente, fornece informações importantes sobre saúde para apoiar a tomada de decisões eficiente.`
            ]
        }
    }
}