
export default function CloseIcon() {
    return (
        <svg
            className="block size-6"
            fill="#0D47A1"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#0D47A1"
            aria-hidden="true"
            data-slot="icon"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
        </svg>
    )
}
