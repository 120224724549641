import { useContext } from "react";

import Card from "../../components/Card";
import Content from "../../components/Content";
import labelWhiteContent from "./translate";
import { LangContext } from "../../context";
import { Layout } from "../../components/Layout";
import { getImageURL } from '../../components/getImageURL'
import { ilsUrls,interassistUrls,wtaUrls } from "../../types/urls"

export default function LabelWhitePage() {

    const { currentLang } = useContext(LangContext);
    const text = labelWhiteContent;
    const title = text[currentLang].title;
    const content = text[currentLang].content;

    const arr_companies = [
                {
                    img: getImageURL('Logo WTA.avif'),
                    href: wtaUrls
                },
                {
                    img: getImageURL('Logo iLS.avif'),
                    href: ilsUrls
                },
                {
                    img: getImageURL('Logo Inter assistance.avif'),
                    href: interassistUrls
                },
    ]

    return (
        <Layout>
            <Card
                img={getImageURL('label-white-image.avif')}
                title={title}
                arr_companies={arr_companies}
                srcSet={`${getImageURL('computer-mobile.avif')} 360w,${getImageURL('label-white-image.avif')}`}
            />
            {
                content && (
                    <Content image={getImageURL('computerImage2.avif')} text={content.text} />
                )
            }
        </Layout>
    );
}
