import { useContext } from "react";
import { Layout } from "../../components/Layout"
import { LangContext } from "../../context";
import Content from "../../components/Content";
import Card from "../../components/Card";
import { getImageURL } from "../../components/getImageURL";
import { technologicalSolutionsContent } from "./translate";
import { eliteGlobalDoctorsUrls } from "../../types/urls";


export const TechnologicalSolutionsPage = () => {
  const { currentLang } = useContext(LangContext);
  const text = technologicalSolutionsContent;
  const title = text[currentLang].title;
  const content = text[currentLang].content;

  const handleClick = () => {
    window.open(eliteGlobalDoctorsUrls[currentLang], '_blank')
  }

  return (
    <Layout>
      <Card
        title={title}
        img={getImageURL("technologicalsolutions.png")}
        srcSet={`${getImageURL('technologicalsolutions-mobile.avif')} 360w,  ${getImageURL('technologicalsolutions.png')}`}
      />
      {
        content && (
          <Content image={getImageURL('eliteglobaldoctors.png')} text={content.text} handleClick={handleClick} />
        )
      }
    </Layout>
  )
}
