import { languagesTypes } from "../../types/types";

const labelWhiteContent = {
  [languagesTypes.english]: {
    title: "WHITE LABELS DEVELOPMENT",
    content: {
      text: [
        `We accompany your brand from conception to the moment of offering an excellent
          quality service guaranteeing repurchase.`,

        `We provide you with all the necessary tools and develop products tailored to your expectations and needs. Our online issuing system
        is customizable and we have a team available 24/7, an online operating system and with access or integration with the client to visualize
        the cases in real time. We have omnichannel communications technology, with phone numbers, WhatsApp, click to call and chatbot, personalized and exclusive for the client.`
      ]
    },
  },
  [languagesTypes.spanish]: {
    title: "DESARROLLO DE MARCAS BLANCAS",
    content: 
      {
        text: [
          `Acompañamos su marca desde el nacimiento hasta el momento de ofrecer un 
          servicio de excelente calidad garantizando la recompra.`,

          `Ponemos a su disposición todas las herramientas necesarias y desarrollamos productos a la 
          medida de sus expectativas y necesidades. Nuestro sistema de emisión en línea es personalizable
          y contamos con un equipo de trabajo disponible 24/7, un sistema operativo en línea y con acceso
          o integración con el cliente para visualización de los casos en tiempo real. Contamos con 
          tecnología de comunicaciones omnicanal, con números telefónicos, whatsapp, click to call
          y chatbot, personalizados y exclusivos para el cliente.`
        ]
      },
  },
  [languagesTypes.portugues]: {
    title: "DESENVOLVIMENTO DE ETIQUETA BRANCA",
    content: {
        text: [
          `Acompanhamos sua marca desde o nascimento até o momento de oferecer
          um serviço de excelente qualidade garantindo a recompra.`,

          `Colocamos à sua disposição todas as ferramentas necessárias e desenvolvemos produtos de 
          acordo com suas expectativas e necessidades. Nosso sistema de emissão online é customizável
          e temos uma equipe de trabalho disponível 24 horas por dia, 7 dias por semana, um sistema
          operativo onlinee com acesso ou integração com o cliente para visualização de casos em 
          tempo real. Contamos com tecnologia de comunicações omnicanal, com números de telefone, 
          whatsapp, click to call e chatbot, personalizados e exclusivos para o cliente.`
        ]
      },
  },
};


export default labelWhiteContent;
