import PropTypes from 'prop-types';
import { useMemo } from "react";

import { LangContext } from "./LangContext"
import useLang from '../../hooks/useLang';

export const LangProvider = ({ children }) => {

    const { currentLang, changeLang, languages } = useLang()

    const providerValues = useMemo(() => ({ currentLang, changeLang, languages }), [currentLang, changeLang, languages])

    return (
        <LangContext.Provider value={ providerValues }>
            {children}
        </LangContext.Provider>
    )
}

LangProvider.propTypes = {
    children: PropTypes.node.isRequired
}