import { useContext } from "react"
import { isMobile } from "react-device-detect"

import { getImageURL } from "./getImageURL"
import { homePageContent } from "../pages/translate"
import { LangContext } from "../context"

export const Stadistics = () => {

    const { currentLang } = useContext(LangContext)

    const stadisticImages = [
        {
            desktop: getImageURL('building.avif'),
            mobile: getImageURL('building-mobile.avif')
        },
        {
            desktop: getImageURL('layer.avif'),
            mobile: getImageURL('layer-mobile.avif')
        },
        {
            desktop: getImageURL('paper.avif'),
            mobile: getImageURL('paper-mobile.avif')
        },
        {
            desktop: getImageURL('assist.avif'),
            mobile: getImageURL('assist-mobile.avif')
        },
        {
            desktop: getImageURL('hospital.avif'),
            mobile: getImageURL('hospital-mobile.avif')
        },
        {
            desktop: getImageURL('team.avif'),
            mobile: getImageURL('team-mobile.avif')
        },
        {
            desktop: getImageURL('computer.avif'),
            mobile: getImageURL('stadistics-computer-mobile.avif')
        },
    ]

    return (
        <section className='flex flex-col gap-[30px] md:gap-[60px] py-[50px] md:px-[95px]'>

            <p className='font-extrabold 2xl:text-[50px] text-[25px] md:text-[40px] leading-[26px] text-center text-[#004899]'>
                {homePageContent[currentLang].stadistics.title}
            </p>
            {/* Mobile version */}
            {
                isMobile && <div className="grid grid-cols-2 overflow-x-hidden">
                    {
                        homePageContent[currentLang].stadistics.stadisticCard.map((stat, index) => (
                            <div className={`w-1/2 ${index === 6 ? 'col-span-1 ml-[50%] ' : ''}`} key={stat.text}>
                                <div className="relative h-[116px] w-[250px] scale-[70%] left-[-28%]">
                                    <div className="absolute left-0 bg-[#144B94] rounded-[23px] p-2 h-full w-[116px]">
                                        <img className="object-contain object-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] border- scale-[0.65]" src={stadisticImages[index].desktop} srcSet={`${stadisticImages[index].mobile} 368w,${stadisticImages[index].desktop}`} 
                                             sizes="(max-width: 600px) 368px, 768px"                                               alt="Stadistic image"  width={'100%'} loading="lazy" />
                                    </div>
                                    <div className="absolute -z-10 left-0 bg-[#D9D9D9] h-[116px] w-[250px] rounded-[23px] pl-[50%] pr-[5px] pt-[5%]">
                                        <p className='font-extrabold  text-[14px] leading-[26px] text-[#22C8AB]'>{stat.number}</p>
                                        <p className='text-[#202020] font-semibold text-[11px] leading-[20px] break-words'>{stat.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
            {/*  Desktop version */}
            {
                !isMobile &&
                <div className='px-[3%] grid md:grid-cols-3 md:gap-[56px] 2xl:gap-[36px]'>

                    {homePageContent[currentLang].stadistics.stadisticCard.map((stat, index) => (

                        <div
                            className={`flex  md:gap-[13px] 2xl:gap-[20px] bg-[#D9D9D9] rounded-[23px] mx-auto card max-w-[100%] ${index === 0 ? 'col-span-1 md:col-span-3 justify-center items-center' : ''}`}
                            key={stat.text}
                        >
                            <div className='bg-[#144B94] md:w-[131px] md:h-[141px] relative rounded-[23px]'>
                                <img src={stadisticImages[index].desktop} srcSet={`${stadisticImages[index].mobile} 368w,${stadisticImages[index].desktop} `} sizes="(max-width: 600px) 368px, 768px" alt="Stadistic image" className="aspect-square scale-[0.9] object-contain block absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" loading="lazy" />
                            </div>
                            <div className='h-full md:pt-[31px]'>
                                <p className='font-extrabold md:text-[26px] 2xl:text-[30px] leading-[26px] text-[#22C8AB] md:w-[130px]'>{stat.number}</p>
                                <p className='text-[#202020] font-semibold md:text-[16px] leading-[20px] md:w-[188px] md:h-full break-words'>{stat.text}</p>
                            </div>
                        </div>

                    ))}
                </div>

            }

        </section>
    )
}
