import { useContext } from 'react'

import Card from '../../components/Card'
import Content from '../../components/Content'
import { getImageURL } from '../../components/getImageURL'
import { Layout } from '../../components/Layout'
import { LangContext } from '../../context'
import { ClaimsAdministratorContent } from './translate'
import { wtaUrls } from '../../types/urls'

export const ClaimsAdministratorPage = () => {

  const { currentLang } = useContext(LangContext);
  const text = ClaimsAdministratorContent;
  const title = text[currentLang].title;
  const content = text[currentLang].content;

  const handleClick = () => {
    window.open(wtaUrls[currentLang], '_blank')
  }

  return (
    <Layout>
      <Card
        title={title}
        img={getImageURL("claimsadministrator.png")}
        srcSet={`${getImageURL('claimsadministrator-mobile.avif')} 360w,  ${getImageURL('claimsadministrator.png')}`}
      />
      {
        content && (
          <Content
            image={getImageURL('wta_logo.png')}
            text={content.text}
            left
            handleClick={handleClick} />
        )
      }
    </Layout>
  )
}
