/* eslint-disable react/prop-types */
import { useContext } from "react"
import { motion } from "framer-motion";


import { LangContext } from "../context"



export default function Card({ title, img, srcSet, arr_companies }) {
  const { currentLang } = useContext(LangContext)

  return (
    <div
      className="px-[5%] md:px-0"
    >
      <motion.div
        className="relative w-full md:max-w-7xl 2xl:max-w-[90%] md:mx-auto h-[144px] md:h-[500px] bg-white rounded-3xl shadow-[inset_-10px_-18px_40px_#46464620] overflow-hidden flex flex-row items-center justify-between"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        {/* section: title */}
        <div className="w-1/2 flex flex-col items-start justify-center space-y-4 p-2 md:p-8 mb-[15px] md:mb-0 2xl:px-[90px]">
          <motion.h1
            className="w-min md:w-auto text-md ml-[10%] md:text-5xl font-bold text-[#69DAC6]"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            {title}
          </motion.h1>
        </div>

        {/* section: image */}
          <motion.img 
            className="absolute object-contain object-center h-full aspect-[2/1] right-[-5%] md:right-[-10%]  mt-auto mb-[-15px] md:mb-0"
            src={img}
            srcSet={srcSet}
            sizes="(max-width: 600px) 368px, 768px" 
            alt="Image"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          />

        {/*Logos optional */}
        <div className="absolute bottom-1 sm:bottom-1 sm:justify-center sm:items-center left-5  sm:left-70">
          {arr_companies && (
            <div className='grid items-center grid-cols-5 gap-[20px] md:gap-[54px]'>
            {arr_companies.map((img, index) => (
                <a className="w-fit object-contain" target="_blank" href={img.href[currentLang]} key={`${arr_companies.img} ${index}`}>
                    <img src={img.img} alt="Companie Logo" />
                </a>
            ))}
        </div>
          )}
        </div>
      </motion.div>
    </div>
  );
}
