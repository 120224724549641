    import { languagesTypes } from "./types";
    
    
    export const  wtaUrls = {
        [languagesTypes.english]: "https://wtabyhas.com/",
        [languagesTypes.spanish]: "https://wtabyhas.com/site/sobre-nosotros",
        [languagesTypes.portugues]: "https://wtabyhas.com/site/sobre-nos",
    }

    export const  ilsUrls = {
        [languagesTypes.english]: "https://ilsols.com/site/?lang=en",
        [languagesTypes.spanish]: "https://ilsols.com/site/?lang=es",
        [languagesTypes.portugues]: "https://ilsols.com/site/?lang=pt",
    }

    export const  interassistUrls = {
        [languagesTypes.english]: "https://interassistbyhas.com/",
        [languagesTypes.spanish]: "https://interassistbyhas.com/?lang=es",
        [languagesTypes.portugues]: "https://interassistbyhas.com/",
    }

    export const  eliteGlobalDoctorsUrls = {
        [languagesTypes.english]:
            "https://eliteglobaldoctors.com/page/es/index.php",
        [languagesTypes.spanish]:
            "https://eliteglobaldoctors.com/page/es/es/index.php",
        [languagesTypes.portugues]:
            "https://eliteglobaldoctors.com/page/es/index.php",
        }

    export const korisUrls = {
        [languagesTypes.english]: "https://korisinsurance.com/?lang=en",
        [languagesTypes.spanish]: "https://korisinsurance.com/?lang=es",
        [languagesTypes.portugues]: "https://korisinsurance.com/?lang=pt",
        }
