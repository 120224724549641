
export default function MenuIcon() {
  return (
    <svg
    className="block size-6"
    fill="#0D47A1"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#0D47A1"
    aria-hidden="true"
    data-slot="icon"
>
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
    />
</svg>
  )
}
