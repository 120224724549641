import { languagesTypes } from "../types/types";
import { isMobile } from "react-device-detect";

export const homePageContent = {
  [languagesTypes.english]: {
    homeBanner: {
      title1: "WE GO",
      title2: "FURTHER",
      button: "Contact us",
    },

    weAre: {
      title:
        "We are HAS, specialists in assistance services, health insurance, and corporate solutions",
      card: [
        {
          text: "WHITE LABEL DEVELOPMENT",
          url: "/white-label-developments",
        },
        {
          text: "CLAIMS ADMINISTRATOR",
          url: "/claims-administrator",
        },
        {
          text: "TECHNOLOGICAL SOLUTIONS FOR HEALTH",
          url: "/technological-solutions",
          loading: isMobile ? "eager" : "lazy",
        },
        {
          text: "INTERNATIONAL HEALTH INSURANCE",
          url: "/international-insurance",
        },
        {
          text: "DIGITAL ECOSYSTEM",
          url: "/emission-system",
        },
        {
          text: "OPERATING SYSTEM",
          url: "/operating-system",
        },
      ],
    },

    supportSecurity: "SUPPORT, SECURITY AND GUARANTEES THROUGH INNOVATIVE SOLUTIONS AND SERVICES",

    whoWeAre: {
        title: "WHO WE ARE?",
        text: "We are a holding company with 24 years of experience, specialized in travel assistance and insurance; international health insurance; technological developments focused on the world of medicine; risk management (TPA) and cost containment.",
      },

    stadistics: {
      title: "HAS IN NUMBERS",
      stadisticCard: [
        {
          number: "+120",
          text: "COMPANIES TRUST US",
        },
        {
          number: "24+ Years",
          text: "IN THE MARKET",
        },
        {
          number: "+800.000",
          text: "SERVICES PROVIDED PER YEAR",
        },
        {
          number: "+1.600.000",
          text: "COMMUNICATIONS PER YEAR WITH SERVICE CENTERS",
        },
        {
          number: "+600.000",
          text: "WORLDWIDE PROVIDERS",
        },
        {
          number: "+700",
          text: "PARTNERS",
        },
        {
          number: "+40",
          text: "IT DEVELOPMENTS IN THE ASSISTANCE SECTOR",
        },
      ],
    },

    offices: "+10 OFFICES LOCATED IN STRATEGIC PLACES AROUND THE WORLD",

    ourHistory: {
      title: 'Our History',
      data: [
        'The story of Health Access Services (HAS) began in 2001 as the shared dream of two families seeking to offer travel assistance services with a different approach: humane, empathetic and effective care.',
        'In an industry as challenging as corporate assistance, HAS did not stop. In 2007, WTA by HAS was born, our own contact center, designed to maintain the human touch that has always distinguished us.',
        `In 2010 we identified an opportunity in Colombia. There we opened WTA Latam, from where we served clients in India and consolidated our philosophy: we don't look for clients, we build customer loyalty.`,
        'With the expansion of our portfolio, in 2012 we launched Interassistance for claims management and TPA services,',
        'and in 2013 we founded ILS Techniks, marking our technological independence and offering tailor-made solutions for our clients. ',
        'Growth did not stop: in 2016 we opened WTA do Brasil ',
        'and WTA Mexico, reinforcing our international presence. ',
        'In 2017, we professionalized as a healthcare provider with WTA IPS by HAS',
        'and founded Koris, our connection with reinsurance. ',
        'During the pandemic, far from stoppingDoctors in 2019, focusing on innovative healthcare solutions such as telemedicine and virtual practices.',
        'Expansion continued with offices in Chile in 2023 ',
        'and Argentina in 2024, reaffirming our commitment to do things better and continue to excel every day.',
      ]
    },

    certifications: "Certifications and accreditations of our group",

    strategicAllies: "Strategic Allies",

    buzon: {
      title: "For more information, please contact us at",
      form: {
        name: "Name",
        email: "E-mail",
        country: "Country",
        phone: "Phone",
        message: "Message",
        terms: "Terms & conditions",
        button: "Send",
      },
    },

    footer: {
      title: "MENU",
      title2: "CONTACT US",
      home: "Home",
      aboutUs: "About us",
      solutions: "Solutions",
      files: [
        {
          text: 'Privacy policy',
          url: '/PRIVACY_POLICY.pdf',
        },
        {
          text: 'Web terms of use',
          url: '/TERMS_OF_USE.pdf',
        },
        {
          text: 'Cookie policy',
          id: 'obj',
        },

      ],
      description:
        "All rights reserved. Developed by ilstechnik.com @2021 Has Companies. All users of our online services agree to comply with the terms of service and privacy policy available to view on our website.",
    },
  },
  [languagesTypes.spanish]: {
    homeBanner: {
      title1: "VAMOS",
      title2: "MÁS ALLÁ",
      button: "Contáctanos",
    },

    weAre: {
      title:
        "Somos HAS, especialistas en servicios de asistencia, seguros de salud y soluciones corporativas",
      card: [
        {
          text: "DESARROLLO DE MARCA BLANCA",
          url: "/white-label-developments",
        },
        {
          text: "ADMINISTRADOR DE RECLAMOS",
          url: "/claims-administrator",
        },
        {
          text: "SOLUCIONES TECNOLÓGICAS PARA LA SALUD",
          url: "/technological-solutions",
        },
        {
          text: "SEGUROS DE SALUD INTERNACIONALES",
          url: "/international-insurance",
        },
        {
          text: "ECOSISTEMA DIGITAL",
          url: "/emission-system",
        },
        {
          text: "SISTEMA OPERATIVO",
          url: "/operating-system",
        },
      ],
    },

    supportSecurity: "RESPALDO, SEGURIDAD Y GARANTÍAS MEDIANTE SOLUCIONES Y SERVICIOS INNOVADORES",

    whoWeAre: {
        title: "¿QUIÉNES SOMOS?",
        text: "Somos un grupo empresarial con 24 años de experiencia, especializado en asistencia y seguros en viaje; seguros de salud internacionales; desarrollos tecnológicos enfocados al mundo de la medicina; gestión de riesgos (TPA) y contención de costos.",
      },

    stadistics: {
      title: "HAS EN NÚMEROS",
      stadisticCard: [
        {
          number: "+120",
          text: "EMPRESAS CONFÍAN EN NOSOTROS",
        },
        {
          number: "Más de 24 Años",
          text: "EN EL MERCADO",
        },
        {
          number: "+800.000",
          text: "SERVICIOS PRESTADOS AL AÑO",
        },
        {
          number: "+1.600.000",
          text: "COMUNICACIONES AL AÑO CON CENTRALES DE SERVICIO",
        },
        {
          number: "+600.000",
          text: "PROVEEDORES EN TODO EL MUNDO",
        },
        {
          number: "+700",
          text: "COLABORADORES",
        },
        {
          number: "+40",
          text: "DESARROLLOS EN EL SECTOR DE LA ASISTENCIA",
        },
      ],
    },

    offices: "+10 OFICINAS SITUADAS EN LUGARES ESTRATÉGICOS DE TODO EL MUNDO",

    ourHistory: {
      title: 'Nuestra Historia',
      data: [
        'La historia de Health Access Services (HAS) comenzó en 2001 como el sueño compartido de dos familias que buscaban ofrecer servicios de asistencia en viajes con un enfoque diferente: una atención humana, empática y efectiva.',
        'En un sector tan desafiante como el de la asistencia corporativa, HAS no se detuvo. En 2007 nació WTA by HAS, nuestro contact center propio, diseñado para mantener ese trato humano que siempre nos ha distinguido.',
        'En 2010 identificamos una oportunidad en Pereira, Colombia, una ciudad que nos conquistó con su calidez. Allí abrimos WTA Latam, desde donde atendimos clientes en India y consolidamos nuestra filosofía: no buscamos clientes, los fidelizamos.',
        'Con la expansión de nuestro portafolio, en 2012 lanzamos Interassistance para la gestión de siniestros y servicios TPA.',
        'y en 2013 fundamos ILS Techniks, marcando nuestra independencia tecnológica y ofreciendo soluciones a medida para nuestros clientes.',
        'El crecimiento no se detuvo: en 2016 abrimos WTA do Brasil.',
        'y WTA México, reforzando nuestra presencia internacional.',
        'En 2017 nos profesionalizamos como prestadores de servicios de salud con WTA IPS by HAS.',
        'y fundamos Koris, nuestro enlace con los reaseguros.',
        'Durante la pandemia, lejos de detenernos, creamos Elite Global Doctors en 2019, enfocándonos en soluciones de salud innovadoras como la telemedicina y los consultorios virtuales.',
        'La expansión continuó con oficinas en Chile en 2023.',
        'y Argentina en 2024, reafirmando nuestro compromiso de hacer las cosas mejor y seguir superándonos cada día.',
      ]
    },

    certifications: "Certificaciones y acreditaciones de nuestro grupo",

    strategicAllies: "Aliados estratégicos",

    buzon: {
      title: "Si desea más información, póngase en contacto con nosotros en",
      form: {
        name: "Nombre",
        email: "Correo electrónico",
        country: "País de residencia",
        phone: "Teléfono de contacto",
        message: "Mensaje",
        terms: "Condiciones de uso",
        button: "Enviar",
      },
    },

    footer: {
      title: "MENÚ",
      title2: "CONTACTO",
      home: "Inicio",
      aboutUs: "Quiénes somos",
      solutions: "Soluciones",
      files: [
        {
          text: 'Políticas de Privacidad',
          url: '/POLITICA_DE_PRIVACIDAD.pdf',
        },
        {
          text: 'Condiciones de Uso Web',
          url: '/CONDICIONES_DE_USO.pdf',
        },
        {
          text: 'Política de cookies',
          id: 'obj',
        },
      ],
      description:
        "Todos los derechos reservados. Desarrollado por ilstechnik.com @2021 Has Companies. Todos los usuarios de nuestros servicios en línea aceptan cumplir las condiciones de servicio y la política de privacidad disponibles para su consulta en nuestro sitio web.",
    },
  },

  [languagesTypes.portugues]: {
    homeBanner: {
      title1: "VAMOS",
      title2: "MAIS ADIANTE",
      button: "Contate-nos",
    },

    weAre: {
      title:
        "Somos HAS, especialistas em serviços de assistência, seguros de saúde e soluções corporativas",
      card: [
        {
          text: "DESENVOLVIMENTO DE ETIQUETA BRANCA",
          url: "/white-label-developments",
        },
        {
          text: "ADMINISTRADOR REIVINDICAÇÕES",
          url: "/claims-administrator",
        },
        {
          text: "SOLUÇÕES TECNOLÓGICAS PARA A SAÚDE",
          url: "/technological-solutions",
        },
        {
          text: "SEGUROS DE SAÚDE INTERNACIONAIS",
          url: "/international-insurance",
        },
        {
          text: "ECOSSISTEMA DIGITAL",
          url: "/emission-system",
        },
        {
          text: "SISTEMA OPERATIVO",
          url: "/operating-system",
        },
      ],
    },

    supportSecurity: "SUPORTE, SEGURANÇA E GARANTIAS POR MEIO DE SOLUÇÕES E SERVIÇOS INOVADORES",

    whoWeAre: {
      title: "QUEM SOMOS?",
      text: "Somos um grupo empresarial com 24 anos de experiência, especializado em assistência e seguros em viagem; seguros de saúde internacionais; desenvolvimentos tecnológicos focados no mundo da medicina; gestão de riscos (TPA) e contenção de custos.",
    },

    stadistics: {
      title: "HAS EM NÚMEROS",
      stadisticCard: [
        {
          number: "+120",
          text: "EMPRESAS CONFIAM EM NÓS",
        },
        {
          number: "Mais de 24 anos",
          text: "NO MERCADO",
        },
        {
          number: "+800.000",
          text: "SERVIÇOS PRESTADOS POR ANO",
        },
        {
          number: "+1.600.000",
          text: "COMUNICAÇÕES POR ANO COM CENTROS DE SERVIÇOS",
        },
        {
          number: "+600.000",
          text: "FORNECEDORES EM TODO O MUNDO",
        },
        {
          number: "+700",
          text: "COLABORADORES",
        },
        {
          number: "+40",
          text: "DESENVOLVIMENTOS NO SETOR DE ASSISTÊNCIA",
        },
      ],
    },

    offices: "+10 ESCRITÓRIOS SITUADOS EM LOCAIS ESTRATÉGICOS NO MUNDO",

    ourHistory: {
      title: 'Nossa História',
      data: [
        'A história da Health Access Services (HAS) começou em 2001 como o sonho compartilhado de duas famílias que buscavam oferecer serviços de assistência em viagens com uma abordagem diferenciada: atenção humana, empática e eficaz.',
        'Num setor tão desafiador como o da assistência corporativa, HAS não parou. Em 2007, nasceu WTA by HAS, nossa central de contato própria, projetado para manter aquele tratamento humano que sempre nos distinguiu.',
        'Em 2010, tivemos uma oportunidade em Pereira, Colômbia, uma cidade que nos conquistou com sua cordialidade. No local, abrimos WTA Latam, de onde atendemos clientes na Índia e consolidamos nossa filosofia: não procuramos clientes, nós construímos fidelidade com nossos clientes.',
        'Com a expansão do nosso portfólio, em 2012 lançamos Interassistência para gestão de sinistros e serviços TPA.',
        ': e em 2013 fundamos ILS Techniks, marcando nossa independência tecnológica e oferecendo soluções sob medida para nossos clientes.',
        'O crescimento não parou: em 2016 abrimos WTA do Brasil',
        'e WTA México, reforçando nossa presença internacional.',
        'Em 2017, nós nos profissionalizamos como prestadores de serviços de saúde com WTA IPS by HAS.',
        ': e fundamos a Koris, nossa ligação com os resseguros.',
        'Durante a pandemia, longe de parar, criamos Elite Global Doctors em 2019, com foco em soluções inovadoras de saúde, como telemedicina e consultórios virtuais.',
        'A expansão continuou com escritórios no Chile em 2023.',
        'e Argentina em 2024, reafirmando nosso compromisso de fazer as coisas da melhor forma possível e continuar a melhorar todos os dias.',
      ]
    },

    certifications: "Certificações e credenciamento do nosso grupo",

    strategicAllies: "Aliados estratégicos",

    buzon: {
      title: "Se você deseja mais informação, entre em contato conosco em",
      form: {
        name: "Nome",
        email: "E-mail",
        country: "País de residência",
        phone: "Telefone de contato",
        message: "Mensagem",
        terms: "Condições de uso",
        button: "Enviar",
      },
    },

    footer: {
      title: "MENU",
      title2: "CONTATO",
      home: "Início",
      aboutUs: "Quem somos",
      solutions: "Soluções",
      files: [
        {
          text: 'Política de privacidade',
          url: '/POLITICA_DE_PRIVACIDADE.pdf',
        },
        {
          text: 'Condições de Uso Web',
          url: '/CONDICONES_DE_USO.pdf',
        },
        {
          text: 'Política de cookies',
          id: 'obj'
        },

      ],
      description:
        "Todos os direitos reservados. Desenvolvido por ilstechnik.com @2021 Has Companies. Todos os usuários de nossos serviços on-line concordam em cumprir os termos de serviço e a política de privacidade disponíveis para visualização em nosso site.",
    },
  },
};
