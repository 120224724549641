import { useContext } from "react"

import { homePageContent } from "../pages/translate"
import { getImageURL } from "./getImageURL"
import { LangContext } from "../context"
import useScrollNavigation from "../hooks/useScroll"
import { ilsUrls, eliteGlobalDoctorsUrls, interassistUrls, korisUrls, wtaUrls } from "../types/urls"

export const HomeBanner = () => {

    const { currentLang } = useContext(LangContext)
    let content = homePageContent[currentLang].homeBanner
    const img_companies = [
        {
            img: getImageURL('Logo WTA.avif'),
            href: wtaUrls
        },
        {
            img: getImageURL('Logo iLS.avif'),
            href: ilsUrls
        },
        {
            img: getImageURL('Logo Inter assistance.avif'),
            href: interassistUrls
        },
        {
            img: getImageURL('Logo Elite Global Doctors.avif'),
            href: eliteGlobalDoctorsUrls
        },
        {
            img: getImageURL('Logo Koris.avif'),
            href: korisUrls
        }
    ]

    const { scrollToSection } = useScrollNavigation()

    return (
        <section className='h-[35vh] md:h-[105vh] 2xl:h-[80vh] pl-[20px] md:pl-[79px] flex'>
            <div className="w-full flex flex-col">
                <div className='w-[70%] md:w-[65%]'>
                    <div className="mb-[20px] md:mb-[69px]">
                        <p className='font-extrabold text-[28px] md:text-[100px] leading-10 md:leading-[110px] text-[#004899]'>{content.title1}</p>
                        <p className='font-extrabold text-[28px] md:text-[100px] leading-10  md:leading-[110px] text-[#004899] text-end 2xl:text-center 2xl:ml-[145px] ml-[25px]'>{content.title2}</p>
                    </div>
                    <div className='grid items-center grid-cols-5 gap-[20px] md:gap-[54px]'>
                        {img_companies.map((img, index) => (
                            <a className="w-fit object-contain" target="_blank" href={img.href[currentLang]} key={`${img_companies.img} ${index}`}>
                                <img src={img.img} alt="Companie Logo" />
                            </a>
                        ))}
                    </div>
                    <div className="2xl:ml-[18%] md:ml-[10%] mt-[20px] md:mt-[90px]">
                        <button onClick={() => scrollToSection('buzonForm')} className="bg-[#004899] text-[#FFFFFF] py-[10px] px-[19px] rounded-[200px] font-bold text-[20px] md:text-[40px] cursor-pointer">
                            {content.button}
                        </button>
                    </div>
                </div>
            </div>
            <img src={getImageURL('banner_background.avif')} width='1095px' height='646px' alt="" className="absolute z-[-1] right-[-66%] md:right-[-51%] 2xl:right-[-28%] top-[0] block" loading="lazy" />
        </section>
    )
}