/* eslint-disable react/prop-types */
import { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LangContext } from "../context";
import Global from "../assets/icons/Global";

export default function TranslateButton({ handleToggle, isActivate }) {
    const { currentLang, changeLang, languages } = useContext(LangContext);

    const handleClick = (lang) => {
        changeLang(lang);
        handleToggle();
    };

    return (
        <div className="relative z-70">
            <button
                aria-label="translate dropdown button"
                type="button"
                className="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer"
                id="user-menu-button"
                aria-expanded={isActivate}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Global />
            </button>

            <AnimatePresence>
                {isActivate && (
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.8 }}
                        className="absolute right-0 mt-2 w-40 rounded-lg bg-white shadow-xl ring-1 ring-black/5 overflow-hidden"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu-button"
                    >
                        {languages.map((lang) => (
                            <button
                                key={lang}
                                onClick={() => handleClick(lang)}
                                className={`block w-full px-4 py-2 text-left text-gray-700 transition-colors ${currentLang === lang
                                    ? "bg-blue-900 text-white"
                                    : "hover:bg-blue-200 hover:text-black"
                                    }`}
                            >
                                {lang}
                            </button>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
