import { useState } from "react"
import { languagesTypes } from "../types/types"

const lang = localStorage.getItem('lang') || languagesTypes.english

const useLang = () => {

    const [currentLang, setCurrentLang] = useState(lang)

    const languages = Object.values(languagesTypes);

    const changeLang = (lang) => {
        localStorage.setItem('lang', lang)
        setCurrentLang(lang)

    }

    return {
        currentLang,
        changeLang,
        languages,
    }

}

export default useLang