import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useScrollNavigation = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateToPage = (sectionId) => {
    if (window.location.pathname === "/form/site/") {
      scrollToSection(sectionId);
    } else {
      navigate(`/?scroll=${sectionId}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get("scroll");
    if (sectionId) {
      scrollToSection(sectionId);
    }
  }, [location]);

  return {
    scrollToSection,
    navigateToPage,
  };
};

export default useScrollNavigation;
