import { useContext } from "react"
import { getImageURL } from "./getImageURL"
import { LangContext } from "../context"
import { useCarousel } from "../hooks/useCarousel"
import { homePageContent } from "../pages/translate"

const data = [
  {
    year: '2001',
    imageUrl: getImageURL('has_logo.avif'),
  },
  {
    year: '2007',
    imageUrl: getImageURL('wta_history.avif'),
  },
  {
    year: '2010',
    imageUrl: getImageURL('wta_latam.avif'),
  },
  {
    year: '2012',
    imageUrl: getImageURL('inter_assistance.avif'),
  },
  {
    year: '2013',
    imageUrl: getImageURL('ils_logo.avif'),
  },
  {
    year: '2016 ',
    imageUrl: getImageURL('wta_brazil.avif'),
  },
  {
    year: '2016',
    imageUrl: getImageURL('wta_mexico.avif'),
  },
  {
    year: '2017 ',
    imageUrl: getImageURL('wta_ips.avif'),
  },
  {
    year: '2017',
    imageUrl: getImageURL('koris_logo.avif'),
  },
  {
    year: '2019 ',
    imageUrl: getImageURL('eliteglobaldoctors.avif'),
  },
  {
    year: '2023',
    imageUrl: getImageURL('has_logo.avif'),
  },
  {
    year: '2024',
    imageUrl: getImageURL('has_logo.avif'),
  },
]

export const OurHistory = () => {

  const { currentLang } = useContext(LangContext)

  const componentContent = homePageContent[currentLang].ourHistory
  const years =  data.map(item => item.year)
  const images = data.map(item => item.imageUrl)
  const content = componentContent.data.map(item => item)

  // Refactorizar estilos en linea
  const { currentIndex, handleBackContent, handleNextContent } = useCarousel(content)

  return (
    <section className="w-full mx-auto overflow-hidden">
      <h3 className=" font-extrabold text-[25px] md:text-[40px] 2xl:text-[45px] leading-[40px] text-center text-[#004899] mb-5">
        {componentContent.title}
      </h3>
      <div className="relative w-[110px] mx-auto mb-8 h-[50px] overflow-hidden">
        {years.map((year, index) => (
          <h5 key={`Year ${index}`} className={`absolute left-[50%] translate-x-[-50%] w-fit mx-auto top-8 font-extrabold text-[25px] md:text-[40px] leading-[40px] text-center text-[#004899] transition-all duration-700`} style={{ top: currentIndex === index ? '0' : `${(index - currentIndex) * 60}px` }}>{year}</h5>
        ))
        }
      </div>
      <div className="transition-all w-[90vw] mx-auto h-[200px] md:mb-3 relative">
        {images.map((image, index) => (
          <img key={`Image ${index}`} src={image} loading="lazy" alt="Company Logo" className='absolute block mx-auto duration-700' style={{
            left: currentIndex === index ? '50%' : `${(index - currentIndex) * 30}%`,
            transform: currentIndex === index ? 'translateX(-50%)' : `translateX(${(index - currentIndex) * 140}%) translateY(100%) scale(0.7)`,
            opacity: currentIndex === index ? 1 : 0.5,
            right: currentIndex === index ? '50%' : `${(index - currentIndex) * 30}%`,
          }} />
        ))
        }
      </div>
      <div className="mx-auto relative h-[200px] md:h-[170px] w-full lg:w-[1000px] overflow-hidden max-w-xl">
        {content.map((content, index) => (
          <p key={`Content ${index}`} className={`absolute px-5 md:px-0 font-medium h-fit w-fit text-[14px] md:text-[18px] leading-[30px] text-[#000000] transition-all duration-700 text-center ${currentIndex === index ? 'opacity-100' : 'opacity-0'}`}>{content}</p>
        ))
        }
      </div>
      <div className="flex flex-row w-[153px] mx-auto mt-2">
        <img src={getImageURL('left_arrow.avif')} alt="Has logo" loading="lazy" className="block cursor-pointer mr-auto" onClick={handleBackContent} />
        <img src={getImageURL('right_arrow.avif')} alt="Has logo" loading="lazy" className="block cursor-pointer" onClick={handleNextContent} />
      </div>
    </section>
  )
}
