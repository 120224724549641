import { BrowserRouter, Routes, Route } from "react-router-dom"

import { HomePage } from "../pages/HomePage"
import LabelWhitePage from "../pages/LabelDevelopments/LabelWhitePage"
import { ClaimsAdministratorPage } from "../pages/ClaimsAdministrator/ClaimsAdministratorPage"
import { TechnologicalSolutionsPage } from "../pages/TechnologicalSolutions/TechnologicalSolutionsPage"
import { EmissionSystemPage } from "../pages/EmissionSystem/EmissionSystemPage"
import { OperatingSystemPage } from "../pages/OperatingSystem/OperatingSystemPage"
import InternationalInsurancePage from "../pages/InternationalInsurance/InternationalInsurancePage"
import NotFound from "../components/NotFound"
import ScriptLoader from "../hooks/useScriptLoader"

export default function AppRouter() {
  return (
    <BrowserRouter basename={import.meta.env.VITE_BASE_NAME}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/white-label-developments" element={<LabelWhitePage />} />
        <Route path="/claims-administrator" element={<ClaimsAdministratorPage />} />
        <Route path="/technological-solutions" element={<TechnologicalSolutionsPage />} />
        <Route path="/emission-system" element={<EmissionSystemPage />} />
        <Route path="/operating-system" element={<OperatingSystemPage />} />
        <Route path="/international-insurance" element={<InternationalInsurancePage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScriptLoader />
    </BrowserRouter>
  )
}
