import { languagesTypes } from "../../types/types";


export const InternationalInsurance = {
    [languagesTypes.english]: {

        title: 'INTERNATIONAL HEALTH INSURANCE',
        content: {
            
            titleContent: 'Global health insurance',
            text: [
                `We offer insurance that adjusts to the needs of individuals, companies or families, with the flexibility and coverage necessary to guarantee peace of mind, wellbeing and care.`
            ],
        },
    },
    [languagesTypes.spanish]: {
        title: 'SEGURO DE SALUD INTERNACIONAL',
        content: {
            titleContent: 'Seguros de salud global',
            text: [
                `Ofrecemos un seguro que se adapta a las necesidades de individuos, empresas o familias, 
                con la flexibilidad y cobertura necesarias para garantizar tranquilidad, bienestar y cuidado.`
            ],
        }
    },
    [languagesTypes.portugues]: {
        title: 'SEGURO DE SAÚDE INTERNACIONAL',
        content: {
            titleContent: 'Seguros de saúde global',
            text: [
                `Oferecemos um seguro que se adapta às necessidades de indivíduos, empresas ou famíias, 
                com a flexibilidade e cobertura necessárias para garantir tranquilidade, bem-estar e cuidado.`
            ],
        }
    }
}