/* eslint-disable react/prop-types */
import { motion } from "framer-motion";

export default function ContentInternationalInsurance({ title, image, text, handleClick }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="flex flex-col px-4 md:flex-col m-8 max-w-6xl  md:mx-auto" 
        >
            {/* Título */}
            <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="text-[20px] md:text-3xl text-blue-900 font-bold text-left"
            >
                {title}
            </motion.h2>

            {/* principal text */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="md:mt-6"
            >
                {text.map((item, index) => (
                    <motion.p
                        className="mt-4 sm:text-[14px] md:text-xl text-gray-900 text-justify font-medium"
                        key={`paragraph-${index}`}
                    >
                        {item}
                    </motion.p>
                ))}
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="flex flex-row mt-4 md:mt-0 gap-2 md:gap-0 justify-end items-center md:p-9"
            >
                
                {/* Image */}
                <motion.img
                    onClick={handleClick}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    alt="Image"
                    src={image}
                    className="cursor-pointer w-1/2 md:w-1/3 md:ml-10 md:p-7"
                />
                
                {/* Adicional text */}
                <motion.h3
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="text-[8px] w-1/2 md:w-auto mt-4 md:mt-0 md:ml-6 md:text-2xl "
                >
                    Underwritten by Koris General Insurance Company Limited and certain underwriters at Lloyd’s
                </motion.h3>
            </motion.div>
        </motion.div>
    );
}
