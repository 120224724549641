import { languagesTypes } from "../../types/types";


export const OperatingSystemContent = {
    [languagesTypes.english]: {
        title: 'OPERATING SYSTEM',
        content: {
            text: [
                `Our team works on platforms developed inhouse, allowing us to guarantee the security and protection of information,
                with full control and traceability in the fulfilment of our service agreements. `,

                `We offer each of our clients, exclusive access to view their cases in real time, while also providing the
                option to seamlessly integrate with their existing systems.`,

                `In our continuous effort to improve the customer experience, we have designed our own automatic refund system, 
                which simplifies the process and provides real-time information on the refund status, offering peace of mind to the end user.`
            ]
        },
    },
    [languagesTypes.spanish]: {
        title: 'SISTEMA OPERATIVO',
        content: {
            text: [
                `Nuestro equipo trabaja sobre plataformas desarrolladas internamente con las que podemos garantizar la seguridad y 
                cuidado de la información, teniendo total control y trazabilidad en el cumplimiento de nuestros acuerdos de servicio.`,

                `Entregamos accesos independientes a cada uno de nuestros clientes para que puedan visualizar sus casos en tiempo real
                y ofrecemos la posibilidad de integrarnos con los sistemas de nuestros clientes.`,

                `En pro de mejorar constantemente la experiencia del cliente, diseñamos nuestro propio sistema automático de reembolsos
                con el cual simplificamos el proceso y podemos proporcionar informacion en tiempo real del estado del reembolso, 
                ofreciendo tranquilidad al usuario final.`
            ]
        }
    },
    [languagesTypes.portugues]: {
        title: 'SISTEMA OPERATIVO',
        content: {
            text: [
                `Nossa equipe trabalha em plataformas desenvolvidas internamente com as quais podemos garantir a segurança e o cuidado das 
                informações, tendo total controle e rastreabilidade no cumprimento de nossos contratos de serviços.` ,

                `Fornecemos acesso independente a cada um de nossos clientes para que possam visualizar seus casos em tempo real
                e oferecemos a possibilidade de integração com os sistemas de nossos clientes.`,

                `Com o objetivo de melhorar constantemente a experiência do cliente, desenhamos um sistema próprio de reembolso automático 
                com o qual simplificamos o processo e podemos fornecer informações em tempo real sobre o estado do reembolso, oferecendo 
                tranquilidade ao usuário final.`

            ]
        }
    }

}