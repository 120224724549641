import { useNavigate } from "react-router-dom";
import { homePageContent } from "../pages/translate";
import { useContext } from "react";
import { LangContext } from "../context";
import { isMobile } from "react-device-detect";
import { getImageURL } from "./getImageURL";

export const WeAre = () => {
    const { currentLang } = useContext(LangContext);
    const navigate = useNavigate();
    const images = [
        getImageURL("wa_img1.webp"),
        getImageURL("wa_img2.webp"),
        getImageURL("wa_img3.webp"),
        getImageURL("wa_img4.webp"),
        getImageURL("wa_img5.webp"),
        getImageURL("wa_img6.webp"),
    ]

    return (
        <section className="flex flex-col gap-[35px] md:gap-[55px] py-[15px] md:py-[52px] px-[20px] md:px-[142px]" id="solutions">
            <p className="text-[#004899] font-bold text-[18px] md:text-[34px] 2xl:text-[45px] leading-none md:leading-[40px] text-center">
                {homePageContent[currentLang].weAre.title}
            </p>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-8 sm:gap-6 md:gap-x-[100px] md:gap-y-[50px]">
                {homePageContent[currentLang].weAre.card.map((wa, index) => (
                    <div
                        key={`Image${index}`}
                        className="relative cursor-pointer shadow-xl white-gradient rounded-[20px] transition-transform transform hover:scale-105"
                        onClick={() => navigate(wa.url)}
                    >
                        <img
                            src={images[index]}
                            alt={`Image ${index}`}
                            width="100%"
                            height="auto"
                            className="rounded-[25px]"
                            loading={isMobile ? "eager" : "lazy"}
                        />

                        <p className="font-bold text-[10px] sm:text-[20px] md:text-[20px] 2xl:text-[25px] leading-none md:leading-[20px] text-[#15467a] absolute bottom-0 left-3 sm:bottom-4 sm:left-4 md:bottom-5 md:left-5 bg-opacity-80 rounded-md w-[80%] md:w-auto ml-[5px] md:ml-[15px]">
                            {wa.text}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
};
