import { useContext, useState } from "react";
import LanguajesButton from "./TranslateButton";
import { LangContext } from "../context";
import { languagesTypes } from "../types/types";
import { motion } from "framer-motion";
import { getImageURL } from "./getImageURL";
import useScrollNavigation from "../hooks/useScroll";
import Menu from "../assets/icons/MenuIcon";
import Close from "../assets/icons/CloseIcon";
import { useNavigate } from "react-router-dom";




export default function Header() {
    const [isActivate, setIsActivate] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [buttonTranslateIsActivate, setButtonTranslateIsActivate] = useState(false);
    const { currentLang } = useContext(LangContext);

    const headerLanguage = {
        [languagesTypes.english]: {
            solutions: "Solutions",
            about: "About Us",
            language: "Language",
            whiteLabelDevelopments: "White Label Developments",
            claimsAdministrator: 'Claims Administrator',
            technologicalSolutions: "Technological Solutions",
            emissionSystem: "Digital Ecosystem",
            operatingSystem: "Operating System",
            internationalInsurance: "International Insurance"
        },


        [languagesTypes.spanish]: {
            whiteLabelDevelopments: "Desarrollo De Marcas Blancas",
            claimsAdministrator: 'Administrador de Reclamaciones',
            technologicalSolutions: "Soluciones Tecnologicas",
            emissionSystem: "Ecosistema Digital",
            operatingSystem: "Sistema Operativo",
            internationalInsurance: "Seguro Medico Internacional",
            solutions: "Soluciones",
            about: "Sobre Nosotros",
            language: "Idioma"
        },

        [languagesTypes.portugues]: {
            whiteLabelDevelopments: "Desenvolvimento de marca branca",
            claimsAdministrator: 'Administrador de Sinistros',
            technologicalSolutions: "Soluções Tecnológicas",
            emissionSystem: "  Ecossistema Digital",
            operatingSystem: "Sistema Operacional",
            internationalInsurance: "Seguro Médico Internacional",
            solutions: "Soluções",
            about: "Quem somos",
            language: "Idioma"
        }
    }

    const navigate = useNavigate()

    // Function to obtain text translation
    const getTranslation = (key) => headerLanguage[currentLang]?.[key] || headerLanguage[languagesTypes.english][key];

    const handleIsActivate = () => {
        setIsActivate(!isActivate);
    };

    const { scrollToSection, navigateToPage } = useScrollNavigation()

    //funtion for scroll to section by id  and toggle menu visibility

    const handleLanguajeToggle = () => {
        setButtonTranslateIsActivate(!buttonTranslateIsActivate);
    };

    window.onload = () => {
        const params = new URLSearchParams(window.location.origin);

        const sectionId = params.get('scroll');
        if (sectionId) {
            scrollToSection(sectionId);

            setTimeout(() => {
                const newUrl = window.location.origin + window.location.pathname;
                alert(newUrl);

                window.history.replaceState({}, '', newUrl);
            }, 500);
        }
    };

    const dropdownItems = [
        { label: getTranslation('whiteLabelDevelopments'), route: 'white-label-developments' },
        { label: getTranslation('claimsAdministrator'), route: 'claims-administrator' },
        { label: getTranslation('technologicalSolutions'), route: 'technological-solutions' },
        { label: getTranslation('internationalInsurance'), route: 'international-insurance' },
        { label: getTranslation('emissionSystem'), route: 'emission-system' },
        { label: getTranslation('operatingSystem'), route: 'operating-system' },
    ]

    const navBarItems = [
        {
            label: getTranslation('solutions'),
            route: '',
            onClick: () => {
                if (window.location.pathname === import.meta.env.VITE_BASE_NAME) {
                    scrollToSection('solutions');
                } else {
                    setDropdownOpen(!dropdownOpen)
                }
            }
        },
        {
            label: getTranslation('about'),
            route: '',
            onClick: () => navigateToPage('AboutUs')
        },
        {
            label: getTranslation('language'),
            route: '',
            onClick: handleLanguajeToggle
        },
    ]

    return (
        <nav className="sticky top-0 left-0 w-full mx-auto max-w-[1920px] z-50 transition-all duration-300 bg-white md:py-[15px] 2xl:py-[50px]">
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* Mobile menu button */}
                        <button
                            type="button"
                            aria-label="page dropdown button"
                            className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-blue-900 hover:text-white focus:ring-2 focus:ring-white focus:outline-hidden focus:ring-inset "
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            onClick={handleIsActivate}
                        >
                            {isActivate ? (
                                <Menu />
                            ) : (
                                <Close />
                            )}
                        </button>
                    </div>
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex shrink-0 items-center">
                            {/* Logo */}
                            <button className="cursor-pointer" onClick={() => navigate('/')}>
                                <img
                                    className="h-8 md:h-12 2xl:h-15 w-auto"
                                    src={getImageURL('logohascolor.avif')}
                                    alt="Has Company Logo"
                                    width={'100%'}
                                />
                            </button>
                        </div>
                        <div className="hidden md:flex w-full justify-end items-center">
                            <div className="flex space-x-1 relative">
                                {/* options of dropdown */}
                                {dropdownOpen && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        transition={{ duration: 0.8 }}
                                    >
                                        <div className="absolute top-10 mt-2 w-48 bg-white shadow-lg rounded-lg py-2 cursor-pointer">
                                            {dropdownItems && (
                                                dropdownItems.map((item) => (
                                                    <button
                                                        key={item.label}
                                                        onClick={() => {
                                                            setDropdownOpen(false)
                                                            navigate(`/${item.route}`)
                                                        }}
                                                        className={`block cursor-pointer px-4 py-2 text-sm font-medium text-gray-700 transition-all duration-500 hover:bg-blue-900 hover:text-white w-full text-start ${window.location.pathname.includes(`/${item.route}`) ? 'bg-blue-900 text-white' : ''}`}

                                                    >
                                                        {item.label}
                                                    </button>
                                                ))
                                            )}
                                        </div>
                                    </motion.div>

                                )}
                                {/* options of navBar */}
                                {navBarItems.map((item) => (
                                    <button
                                        key={item.label}
                                        onClick={item.onClick}
                                        className="rounded-md transition-all duration-500 px-6 py-2 text-xl font-medium dark:text-gray-900  hover:bg-blue-900 hover:text-white cursor-pointer"
                                    >
                                        {item.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">
                        <LanguajesButton handleToggle={handleLanguajeToggle} isActivate={buttonTranslateIsActivate} />
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className={`absolute bg-white z-50 top-[60px] w-full transition-all duration-[1s,15s] ${isActivate ? 'opacity-100 clip-path-rectangle' : 'opacity-0 clip-path-none'}`} id="mobile-menu" >
                <div className="space-y-1 px-2 pt-2 pb-3">
                    {navBarItems && (
                        navBarItems.map((item) => (
                            <button
                                key={item.label}
                                className="block rounded-md px-3 py-2 text-base font-medium dark:text-gray-900 hover:bg-blue-900 hover:text-white cursor-pointer"
                                onClick={item.onClick}
                            >
                                {item.label}
                            </button>
                        ))
                    )}
                    {/* Dropdown for options */}
                    {dropdownOpen && (
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.8 }}
                        >
                            <div className="flex flex-col  shadow-lg rounded-lg py-5">

                                {dropdownItems && (
                                    dropdownItems.map((item) => (
                                        <button
                                            key={item.label}
                                            className={`block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-blue-900 hover:text-white w-full text-start ${window.location.pathname.includes(`/${item.route}`) ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => {
                                                navigate(`/${item.route}`)
                                                setDropdownOpen(false);
                                            }}
                                        >
                                            {item.label}
                                        </button>
                                    ))
                                )}
                            </div>
                        </motion.div>
                    )}
                </div>
            </div>
        </nav>
    );
}
