import PropTypes from 'prop-types'
import { motion } from "framer-motion";

import Footer from './Footer'
import Header from './Header'
import ScrollToTop from '../helpers/ScrollToTop'
export const Layout = ({ children }) => {
    return (
        <>
            <ScrollToTop />
            <Header />
            <motion.div
                initial={{ opacity: 0 }}
                exit={{ opacity: 0, transition: { duration: 0.7 } }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0, duration: 0.7 }}
                className='relative w-full mx-auto pt-[50px] md:pt-[80px] max-w-[1920px] overflow-x-hidden'>
                {children}
            </motion.div>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}