import { useContext } from "react";
import Card from "../../components/Card";
import { getImageURL } from "../../components/getImageURL";
import { Layout } from "../../components/Layout";
import { LangContext } from "../../context";
import ContentInternationalInsurance from "../../components/ContentInternationalInsurance";
import { InternationalInsurance } from "./translate";
import { korisUrls } from "../../types/urls";



export default function InternationalInsurancePage() {

    const { currentLang } = useContext(LangContext);
    const text = InternationalInsurance;
    const title = text[currentLang].title;
    const content = text[currentLang].content;

    const handleClick = () => {
        window.open(korisUrls[currentLang], '_blank')
    }

    return (
        <Layout>
            <Card 
            img={getImageURL("doctor.png")} 
            srcSet={`${getImageURL('doctor-mobile.avif')} 360w, ${getImageURL('doctor.png')}`}
            title={title}
            />
            <ContentInternationalInsurance
                handleClick={handleClick}
                title={content.titleContent}
                text={content.text}
                image={getImageURL("koris_lloyds.png")}
            />
        </Layout>
    )
}
